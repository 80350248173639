"use client";
import Link from "next/link";
import {
  ArrowLeft,
  BarChart3,
  Building,
  CircleDollarSignIcon,
  Edit3,
  FileInput,
  Home,
  LayoutDashboard,
  Menu,
  Network,
  Newspaper,
  Settings,
  UserCircle,
  LucideStickyNote,
  LibraryIcon,
  Globe,
  Package,
  ArrowDownUp,
  Receipt,
} from "lucide-react";
import {
  useParams,
  usePathname,
  useSelectedLayoutSegments,
} from "next/navigation";
import { ReactNode, useEffect, useMemo, useState } from "react";
import Image from "next/image";
import { Server } from "lucide-react";
import { useTranslation } from "@/app/i18n/client";
import { useRootStore } from "@/src/stores/rootStoreProvider";
import { PERMISSIONS } from "@/src/lib/constants";
import { getSiteFromPostId } from "@/src/actions/site/site";

const externalLinks: any = [];

export default function Nav({ children }: { children: ReactNode }) {
  const rootStore = useRootStore();
  const { t } = useTranslation("en", "common");
  const segments = useSelectedLayoutSegments();

  const { id } = useParams() as { id?: string };

  const [siteId, setSiteId] = useState<string | null>();

  useEffect(() => {
    if (segments[0] === "post" && id) {
      getSiteFromPostId(id).then((id) => {
        setSiteId(id);
      });
    }
  }, [segments, id]);

  const defaultMenuItems = ({ t, segments }: any) => {
    const menuItems = [
      {
        name: t("nav.overview"),
        href: "/",
        isActive: segments.length === 0,
        icon: <LayoutDashboard width={18} />,
      },
      {
        name: t("nav.user"),
        href: "/users",
        isActive: segments[0] === "users",
        icon: <UserCircle width={18} />,
        permission: PERMISSIONS.user.read,
      },
      {
        name: t("nav.salary"),
        href: "/salary",
        isActive: segments[0] === "salary",
        icon: <CircleDollarSignIcon width={18} />,
        permission: PERMISSIONS.salary.read,
      },
      {
        name: t("nav.revenue"),
        href: "/revenue",
        isActive: segments[0] === "revenue",
        icon: <FileInput width={18} />,
        permission: PERMISSIONS.revenue.read,
      },
      {
        name: t("nav.roles"),
        href: "/roles",
        isActive: segments[0] === "roles",
        icon: <Network width={18} />,
        permission: PERMISSIONS.role.read,
      },
      {
        name: t("nav.masterData"),
        href: "/master-data",
        isActive: segments[0] === "master-data",
        icon: <Server width={18} />,
        permission: PERMISSIONS.masterData.read,
      },
      {
        name: "Settings",
        href: "/settings",
        isActive: segments[0] === "settings",
        icon: <Settings width={18} />,
        permission: PERMISSIONS.setting.read,
      },
      {
        name: t("nav.property"),
        href: "/property",
        isActive: segments[0] === "property",
        icon: <Home width={18} />,
        permission: PERMISSIONS.property.read,
      },
      {
        name: t("nav.project"),
        href: "/project",
        isActive: segments[0] === "project",
        icon: <Building width={18} />,
        permission: PERMISSIONS.project.read,
      },
      {
        name: "Location Group",
        href: "/location-group/bulk",
        isActive: segments[0] === "/location-group",
        icon: <Globe width={18} />,
        permission: PERMISSIONS.locationGroup.read,
      },
      {
        name: t("nav.campaign"),
        href: "/campaign",
        isActive: segments[0] === "campaign",
        icon: <LibraryIcon width={18} />,
        permission: PERMISSIONS.campaign.read,
      },
      {
        name: t("nav.post"),
        href: "/posts",
        isActive: segments[0] === "posts",
        icon: <LucideStickyNote width={18} />,
        permission: PERMISSIONS.masterPost.read,
      },
      {
        name: "Sites",
        href: "/sites",
        isActive: segments[0] === "sites",
        icon: <Globe width={18} />,
        permission: PERMISSIONS.site.read,
      },
    ];

    return menuItems.filter((item) => rootStore.isGranted(item.permission));
  };

  const siteMenuItems = ({ siteId, segments }: any) => {
    const menuItems = [
      {
        name: "Back to All Sites",
        href: "/sites",
        icon: <ArrowLeft width={18} />,
      },
      {
        name: "Posts",
        href: `/site/${siteId}`,
        isActive: segments.length === 2,
        icon: <Newspaper width={18} />,
        permission: PERMISSIONS.post.read,
      },
      {
        name: "Product",
        href: `/site/${siteId}/menu`,
        isActive: segments.length === 2,
        icon: <Package width={18} />,
        permission: PERMISSIONS.menu.read,
      },
      {
        name: "Order",
        href: `/site/${siteId}/order`,
        isActive: segments.length === 2,
        icon: <ArrowDownUp width={18} />,
        permission: PERMISSIONS.menu.read,
      },
      {
        name: "Receipt",
        href: `/site/${siteId}/receipt`,
        isActive: segments.length === 2,
        icon: <Receipt width={18} />,
        permission: PERMISSIONS.menu.read,
      },
      {
        name: "Analytics",
        href: `/site/${siteId}/analytics`,
        isActive: segments.includes("analytics"),
        icon: <BarChart3 width={18} />,
      },
      {
        name: "Settings",
        href: `/site/${siteId}/settings`,
        isActive: segments.includes("settings"),
        icon: <Settings width={18} />,
        permission: PERMISSIONS.site.update,
      },
    ];

    return menuItems.filter((item) => rootStore.isGranted(item.permission));
  };

  const postMenuItems = ({ siteId, id, segments }: any) => {
    const menuItems = [
      {
        name: "Back to All Posts",
        href: siteId ? `/site/${siteId}` : "/sites",
        icon: <ArrowLeft width={18} />,
      },
      {
        name: "Editor",
        href: `/post/${id}`,
        isActive: segments.length === 2,
        icon: <Edit3 width={18} />,
        permission: PERMISSIONS.post.update,
      },
      {
        name: "Settings",
        href: `/post/${id}/settings`,
        isActive: segments.includes("settings"),
        icon: <Settings width={18} />,
        permission: PERMISSIONS.post.update,
      },
    ];
    return menuItems.filter((item) => rootStore.isGranted(item.permission));
  };

  const tabs = useMemo(() => {
    if (segments[1] === "site" && id) {
      return siteMenuItems({
        siteId: id,
        segments,
        isGranted: rootStore.isGranted,
      });
    } else if (segments[1] === "post" && id) {
      return postMenuItems({
        siteId,
        id,
        segments,
        isGranted: rootStore.isGranted,
      });
    }
    const menuItems = defaultMenuItems({
      t,
      segments,
      isGranted: rootStore.isGranted,
    });

    return menuItems;
  }, [segments, id, siteId, rootStore.permissions]);

  const [showSidebar, setShowSidebar] = useState(false);

  const pathname = usePathname();

  useEffect(() => {
    // hide sidebar on path change
    setShowSidebar(false);
  }, [pathname]);

  return (
    <>
      <button
        className={`fixed z-20 ${
          // left align for Editor, right align for other pages
          segments[0] === "post" && segments.length === 2 && !showSidebar
            ? "left-5 top-5"
            : "right-5 top-7"
        } sm:hidden`}
        onClick={() => setShowSidebar(!showSidebar)}
      >
        <Menu width={20} />
      </button>
      <div
        className={`transform ${
          showSidebar ? "w-full translate-x-0" : "-translate-x-full"
        } fixed z-10 flex h-full flex-col justify-between border-r border-stone-200 bg-stone-100 p-4 transition-all sm:w-60 sm:translate-x-0 dark:border-stone-700 dark:bg-stone-900`}
      >
        <div className="grid gap-2">
          <div className="flex items-center space-x-2 rounded-lg px-2 py-1.5">
            <a
              href="/"
              rel="noopener noreferrer"
              className="rounded-lg p-1.5 hover:bg-stone-200 dark:hover:bg-stone-700"
            >
              <svg
                width="26"
                viewBox="0 0 76 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-black dark:text-white"
              >
                <path
                  d="M37.5274 0L75.0548 65H0L37.5274 0Z"
                  fill="currentColor"
                />
              </svg>
            </a>
            <div className="h-6 rotate-[30deg] border-l border-stone-400 dark:border-stone-500" />
            <Link
              href="/"
              className="rounded-lg p-2 hover:bg-stone-200 dark:hover:bg-stone-700"
            >
              <Image
                src="/logo.png"
                width={24}
                height={24}
                alt="Logo"
                className="dark:scale-110 dark:rounded-full dark:border dark:border-stone-400"
              />
            </Link>
          </div>
          <div className="grid gap-1">
            {tabs.map(({ name, href, isActive, icon }) => (
              <Link
                key={name}
                href={href}
                className={`flex items-center space-x-3 ${
                  isActive ? "bg-stone-200 text-black dark:bg-stone-700" : ""
                } rounded-lg px-2 py-1.5 transition-all duration-150 ease-in-out hover:bg-stone-200 active:bg-stone-300 dark:text-white dark:hover:bg-stone-700 dark:active:bg-stone-800`}
              >
                {icon}
                <span className="text-sm font-medium">{name}</span>
              </Link>
            ))}
          </div>
        </div>
        <div>
          <div className="grid gap-1">
            {externalLinks.map(({ name, href, icon }: any) => (
              <a
                key={name}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between rounded-lg px-2 py-1.5 transition-all duration-150 ease-in-out hover:bg-stone-200 active:bg-stone-300 dark:text-white dark:hover:bg-stone-700 dark:active:bg-stone-800"
              >
                <div className="flex items-center space-x-3">
                  {icon}
                  <span className="text-sm font-medium">{name}</span>
                </div>
                <p>↗</p>
              </a>
            ))}
          </div>
          <div className="my-2 border-t border-stone-200 dark:border-stone-700" />
          {children}
        </div>
      </div>
    </>
  );
}
